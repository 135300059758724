export const FirebaseConfig = {
	"projectId": "a2pride",
	"appId": "1:344838082589:web:5d558f40374c1cc04276f4",
	"databaseURL": "https://a2pride-default-rtdb.firebaseio.com",
	"storageBucket": "a2pride.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyC8JOqAb9gr5dLFe4Fw2m-uhiWQ2xEdo_8",
	"authDomain": "a2pride.firebaseapp.com",
	"messagingSenderId": "344838082589",
	"measurementId": "G-J3XYY0M2GC"
};